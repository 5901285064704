<template>
  <div class="container">
    <headers></headers>
    <right></right>
    <scrollpage></scrollpage>

    <div class="w box_sizing width_box my_wrap">
      <div class="left_position">
        <left></left>
      </div>

      <div class="banner_detail box_sizing">
        <!-- 提示音 -->
        <audio ref="audio" src="../../assets/dou.mp3"></audio>
        <div class="listTitle flex_box flex_justify_between flex_align_center box_sizing">
          <!-- <div class="question_type_name">{{ name }}</div> -->
          <!-- <div class="RA"></div> -->
          <!-- 提示音 -->
          <audio ref="audio" src="../../assets/yinxiao.mp3"></audio>
          <div style="color: #8C9198;">{{ topic_all_num-topic_count+suoYin }}/{{ topic_all_num }}</div>
          <div>{{tiXingType}}模块作答倒计时：{{ exam_surplus_sort_date }}</div>
          <div>考试剩余时间：{{ exam_surplus_date }}</div>
          <!-- <div class="save">保存作答</div> -->
          <!-- <img
            src="../../assets/images/guanbi.png"
            alt=""
            @click="goBack"
            style="width: 16px"
          /> -->
        </div>
        <div style="padding: 56px 0" class="box_sizing">
          <div class="topic_type_name" v-if="tiXingType == 20">{{ detail.number }}</div>
          <div class="topic_type_name" v-else>{{ detail.name }}</div>
          <!-- <div class="topic_type_info box_sizing flex_box flex_align_center">
                        <div class="topic_type_info_time">{{ detail.createtime_text }}</div>
                    </div> -->
          <div class="topic_type_title">{{ detail.describe }}</div>
          <div v-if="tiXingType == 1">
            <div class="topic_type_timu_box box_sizing">
              <span v-for="(item, index) in detail.show_text" :key="index" v-html="item"></span>
            </div>
            <div class="dati_time box_sizing flex_box flex_align_center justify_end" v-if="daojishiTime > 0">
              {{ daojishiTime }}s后开始答题
            </div>
            <div class="dati_time box_sizing flex_box flex_align_center justify_end" v-else>
              答题时间：{{ datiTime }}
            </div>
            <div class="topic_type_answer_box box_sizing" style="min-height: 200px;margin-top: 50px;"
              v-if="daojishiTime >= 0">
              <div class="topic_type_answer_title">Recorded Answer</div>
              <div class="topic_type_tt"></div>
              <div id="siri-classic" style="width: 100%;height: 100px"></div>

              <audio-cmponent ref="AudioC" :key="componentKey" @audioDuration="audioDuration"
                @GetAudioData="GetAudioDataEven" @BeginEven="BeginEvenF" @play="playEvent"
                @notplay="notplayEvent"></audio-cmponent>
            </div>
          </div>

          <div v-if="tiXingType == 2 || tiXingType == 4 || tiXingType == 5">
            <div class="mp3_tool mp3_tool2 box_sizing">
              <div>
                <mp3-tool ref="mp3" :src-url="detail.file"></mp3-tool>
              </div>
            </div>
            <div class="dati_time box_sizing flex_box flex_align_center justify_end" v-if="daojishiTime > 0">
              {{ daojishiTime }}s后开始答题
            </div>
            <div class="dati_time box_sizing flex_box flex_align_center justify_end" v-else>
              答题时间：{{ datiTime }}
            </div>
            <!-- <img class="my_quest3" v-if="detail.image" :src="detail.image" /> -->
            <div class="topic_type_answer_box box_sizing" style="min-height: 200px;">
              <div class="topic_type_answer_title">Recorded Answer</div>
              <div class="topic_type_tt"></div>
              <!--              <div class="topic_type_ttred">Recording…</div>-->
              <div id="siri-classic2" style="width: 100%;height: 100px"></div>
              <audio-cmponent ref="AudioC" :key="componentKey" @audioDuration="audioDuration"
                @GetAudioData="GetAudioDataEven" @BeginEven="BeginEvenF" @play="playEvent"
                @notplay="notplayEvent"></audio-cmponent>
            </div>
          </div>

          <div v-if="tiXingType == 3" style="display: flex;">
            <div style="width: 50%;">
              <img class="my_quest3" :src="detail.image" />
            </div>
            <div style="width: 50%;">
              <div class="dati_time box_sizing flex_box flex_align_center justify_end" style="margin: 0;"
                v-if="daojishiTime > 0">
                {{ daojishiTime }}s后开始答题
              </div>
              <div class="dati_time box_sizing flex_box flex_align_center justify_end" style="margin: 0;" v-else>
                答题时间：{{ datiTime }}
              </div>
              <div class="topic_type_answer_box box_sizing" style="min-height: 200px;">
                <div class="topic_type_answer_title">Recorded Answer</div>
                <div class="topic_type_tt"></div>
                <!--            <div class="topic_type_ttred">qRecording…</div>-->
                <div id="siri-classic3" style="width: 100%;height: 100px"></div>
                <audio-cmponent ref="AudioC" :key="componentKey" @audioDuration="audioDuration"
                  @GetAudioData="GetAudioDataEven" @BeginEven="BeginEvenF" @play="playEvent"
                  @notplay="notplayEvent"></audio-cmponent>
              </div>
            </div>
          </div>

          <div v-if='tiXingType == 9'>
            <!-- 题目区域 -->
            <div class="topic_type_timu_box box_sizing">
              <span v-for="(item, index) in filteredShowText" :key="index" class="panding_change"
                @click="dianjiClick(item)" v-html="item"></span>
            </div>
            <div class="dati_time box_sizing flex_box flex_align_center justify_end" v-if="daojishiTime > 0">
              {{ daojishiTime }}s后开始答题
            </div>
            <div class="dati_time box_sizing flex_box flex_align_center justify_end" v-else>
              答题时间：{{ datiTime }}
            </div>
            <div class="topic_type_answer_box box_sizing">
              <div class="my_questins4 flex_box">
                <div class="my_questins4_title flex_box" v-if="detail.extend">
                  {{ detail.extend.topic }}
                  <!-- <div>{{detail.extend.daan}}</div> -->
                  <!-- . -->
                </div>
                <div class="my_questins4_box flex_box" v-if="detail.extend">
                  <div class="my_questins4_list flex_box" v-for="(item, index) in detail.extend.extend_MA" :key="index"
                    @click="selectDaan(index)">
                    <img v-if="item.select == 0" src="../../assets/images/checkbox_nochoose.png" />
                    <img v-else-if="item.select == 1" src="../../assets/images/checkbox_choose.png" />
                    <img v-else-if="item.select == 2" src="../../assets/images/zhengque_Icon@2x.png" />
                    <img v-else-if="item.select == 3" src="../../assets/images/guanbi_icon@2x.png" />
                    <div :class="[item.select == 2 ? 'my_questins4_color' : '']">
                      {{item.key}}. {{ item.content }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-if='tiXingType == 11'>
            <div class="topic_type_timu_box box_sizing">
              <div class="dati_time box_sizing flex_box flex_align_center justify_end" v-if="daojishiTime > 0">
                {{ daojishiTime }}s后开始答题
              </div>
              <div class="dati_time box_sizing flex_box flex_align_center justify_end" v-else>
                答题时间：{{ datiTime }}
              </div>
              <span v-for="(item, index) in detail.extend.FIB" :key="index">
                <span v-if="item.index == 0" @click="dianjiClick(item.word)" v-html="item.word">
                </span>
                <span class="panding_change" :data-txt="item.word" v-else @dragenter="deagenterDiv"
                  @dragover="dragoverDiv" @drop="dropDiv" style="
                  height: 16px;
                  border-bottom: 1px solid rgba(6, 18, 30, 1);
                  width: 150px;
                  text-align: center;
                  margin: 3px;
                " v-html="daanObj[item.word]"></span>
                <span class="panding_change" v-if="spanShow && item.index == 1" :style="{
                  color: item.word === daanObj[item.word] ? '#0ff507' : 'red',
                }">&nbsp;({{ item.word }})&nbsp;</span>
              </span>
            </div>
            <div class="box_sizing flex_box flex-warp" style="width: 100%;">
              <div class="box_sizing flex_box flex-warp" style="width: 100%" v-if="detail.extend.keyword">
                <div v-for="(item, index) in detail.extend.keyword" :key="index">
                  <div class="yuansu_list box_sizing flex_box flex_align_center flex_justify_center" @drag="dragDiv"
                    @dragover="dropOver($event)" :draggable="true" :data-text="item"
                    @dragstart="(e) => dragstartHandler(e,index)" v-if="daanIndexArr.indexOf(index) < 0" v-html="item">
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-if="tiXingType == 12">
            <div class="dati_time box_sizing flex_box flex_align_center justify_end">答题时间：{{datiTime}}
            </div>
            <div class="topic_type_timu_box box_sizing">
              <block v-for="(item, index) in detail.extend.FIB_RW" :key="index">
                <span v-if="typeof(item.word) == 'string'" v-html="item.word"></span>
                <select v-if="Array.isArray(item.word)" @change="selectChange(item, $event)">
                  <option value=""></option>
                  <option v-for="(items, indexs) in item.word" :value="items.word" :key='indexs'>{{ items.word }}
                  </option>
                </select>
                <span v-for="(items, indexs) in item.word" :value="items.word" :key='indexs' style="color: #0ff507;"
                  v-html="spanShow && items.daan == 1 ? items.word : ''"></span>
              </block>
            </div>
          </div>

          <div v-if="tiXingType == 20 || tiXingType == 13">
            <div class="dati_time box_sizing flex_box flex_align_center justify_end">答题时间：{{datiTime}}
            </div>
            <div class="mp3_tool mp3_tool2 box_sizing">
              <div style="margin: 20px 0px">
                <mp3-tool ref="mp3" :src-url="detail.file"></mp3-tool>
              </div>
            </div>
            <div class="topic_type_answer_box box_sizing" style="min-height: auto;">
              <div :class="tiXingType == 20 ? 'topic_textarea_box' : 'topic_textarea_box_wfd'">
                <textarea placeholder="请输入" v-model="content" @keyup="computedWordCount()"></textarea>
              </div>
              <div class="" style="margin-bottom: 20px !important">
                Word：{{ wordCount }}
              </div>
            </div>
          </div>

          <div v-if="tiXingType == 6 || tiXingType == 7">
            <div class="topic_type_timu_box box_sizing">
              <span v-for="(item, index) in detail.show_text" :key="index" v-html='item'
                @click="dianjiClick(item)"></span>
            </div>
            <div class="dati_time box_sizing flex_box flex_align_center justify_end" v-if="daojishiTime > 0">
              {{ daojishiTime }}s后开始答题
            </div>
            <div class="dati_time box_sizing flex_box flex_align_center justify_end" v-else>
              答题时间：{{ datiTime }}
            </div>
            <div class="topic_type_answer_box box_sizing" style="height: auto">
              <div class="topic_textarea_box">
                <textarea placeholder="请作答" v-model="content" @keyup="computedWordCount()"></textarea>
              </div>
              <div class="">
                Word：{{ wordCount }}

              </div>
            </div>
          </div>

          <div v-if="tiXingType == 8">
            <div class="topic_type_timu_box box_sizing">
              <span v-for="(item, index) in filteredShowText" :key="index" class="panding_change"
                @click="dianjiClick(item)" v-html="item"></span>
            </div>
            <div class="topic_type_answer_box box_sizing">
              <div class="dati_time box_sizing flex_box flex_align_center justify_end">
                答题时间：{{ datiTime }}
              </div>
              <div class="my_questins4 flex_box">
                <div class="my_questins4_title flex_box" v-if="detail.extend">
                  {{ detail.extend.topic }}
                  <!-- <div>{{detail.extend.daan}}</div> -->
                  <!-- . -->
                </div>
                <div class="my_questins4_box flex_box" v-if="detail.extend">
                  <div class="my_questins4_list flex_box" v-for="(item, index) in detail.extend.extend_SA" :key="index"
                    @click="selectDxDaan(index)">
                    <img v-if="item.select == 0" src="../../assets/images/weixuanzhong_icon@2x.png" />
                    <img v-else-if="item.select == 1" src="../../assets/images/xuanzhong_Icon@2x.png" />
                    <img v-else-if="item.select == 2" src="../../assets/images/zhengque_Icon@2x.png" />
                    <img v-else-if="item.select == 3" src="../../assets/images/guanbi_icon@2x.png" />
                    <div :class="[item.select == 2 ? 'my_questins4_color' : '']">
                      {{item.key}}. {{ item.content }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-if="tiXingType == 16 || tiXingType == 17 || tiXingType == 18">
            <div class="mp3_tool mp3_tool2 box_sizing">
              <div style="margin: 20px 0px">
                <mp3-tool ref="mp3" :src-url="detail.file"></mp3-tool>
              </div>
            </div>
            <div class="topic_type_answer_box box_sizing">
              <div class="dati_time box_sizing flex_box flex_align_center justify_end" v-if="daojishiTime > 0">
                {{ daojishiTime }}s后开始答题
              </div>
              <div class="dati_time box_sizing flex_box flex_align_center justify_end" v-else>
                答题时间：{{ datiTime }}
              </div>
              <div class="my_questins4 flex_box">
                <div class="my_questins4_title flex_box" v-if="detail.extend">
                  {{ detail.extend.topic }}
                  <!-- <div>{{detail.extend.daan}}</div> -->
                  <!-- . -->
                </div>
                <div class="my_questins4_box flex_box" v-if="detail.extend">
                  <div class="my_questins4_list flex_box" v-for="(item, index) in detail.extend.extend_SA" :key="index"
                    @click="selectDxDaan(index)">
                    <img v-if="item.select == 0" src="../../assets/images/weixuanzhong_icon@2x.png" />
                    <img v-else-if="item.select == 1" src="../../assets/images/xuanzhong_Icon@2x.png" />
                    <img v-else-if="item.select == 2" src="../../assets/images/zhengque_Icon@2x.png" />
                    <img v-else-if="item.select == 3" src="../../assets/images/guanbi_icon@2x.png" />
                    <div :class="[item.select == 2 ? 'my_questins4_color' : '']">
                      {{ item.key }}.{{ item.content }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-if="tiXingType == 10">
            <!-- 段落排序 -->
            <div
              class="dati_time box_sizing flex_box flex_align_center justify_end"
            >
              答题时间：{{ datiTime }}
            </div>
            <div class="topic_type_answer_box box_sizing">
              <div class="flex_align_center flex_box">
                <div class="topic_type_answer_box box_sizing">
                  <div class="title17">Source</div>
                  <div class="flex_box flex_align_center box_sizing choose17" v-for="(item, index) in choose"
                    :key="index" :class="chooseOne == index ? 'choose' : ''" @click="chooseSource(index)">
                    <div class="p17">{{ item.show_index }}</div>
                    <div :class="chooseOne == index ? 'chooseP' : ''">
                      {{ item.content }}
                    </div>
                  </div>
                </div>
                <img src="../../assets/images/choose.png" alt="" @click="exchange"
                  style="width: 44px; height: 44px; margin: 0 15px" />
                <div class="topic_type_answer_box box_sizing" style="position: relative">
                  <div class="title17">Correct Answer</div>
                  <div class="flex_box flex_align_center box_sizing choose17" v-for="(item, index) in rightChoose"
                    :key="index" :class="chooseTwo == index ? 'choose' : ''" @click="chooseCorrect(index)">
                    <div class="p17">{{ item.show_index }}</div>
                    <div :class="chooseTwo == index ? 'chooseP' : ''">
                      {{ item.content }}
                    </div>
                  </div>
                  <div class="pic17">
                    <img src="../../assets/images/shang.png" alt="" @click="chooseUp" />
                    <img src="../../assets/images/xia.png" alt="" @click="chooseDown" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-if="tiXingType == 14">
            <div class="dati_time box_sizing flex_box flex_align_center justify_end">答题时间：{{datiTime}}
            </div>
            <div class="mp3_tool mp3_tool2 box_sizing">
              <div style="margin: 20px 0px">
                <mp3-tool ref="mp3" :src-url="detail.file"></mp3-tool>
              </div>
            </div>
            <div class="topic_type_answer_box box_sizing">
              <div class="my_questins4 flex_box">
                <div class="my_questins4_title flex_box" v-if="detail.extend">
                  {{ detail.extend.topic }}
                  <!-- <div>{{detail.extend.daan}}</div> -->
                  <!-- . -->
                </div>
                <div class="my_questins4_box flex_box" v-if="detail.extend">
                  <div class="my_questins4_list flex_box" v-for="(item, index) in detail.extend.extend_MA" :key="index"
                    @click="selectDaan(index)">
                    <img v-if="item.select == 0" src="../../assets/images/checkbox_nochoose.png" />
                    <img v-else-if="item.select == 1" src="../../assets/images/checkbox_choose.png" />
                    <img v-else-if="item.select == 2" src="../../assets/images/zhengque_Icon@2x.png" />
                    <img v-else-if="item.select == 3" src="../../assets/images/guanbi_icon@2x.png" />
                    <div :class="[item.select == 2 ? 'my_questins4_color' : '']">
                      {{item.key}}. {{ item.content }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-if="tiXingType == 15">
            <div class="dati_time box_sizing flex_box flex_align_center justify_end">答题时间：{{datiTime}}
            </div>
            <div class="mp3_tool mp3_tool2 box_sizing">
              <div style="margin: 20px 0px">
                <mp3-tool ref="mp3" :src-url="detail.file"></mp3-tool>
              </div>
            </div>
            <div class="topic_type_answer_box box_sizing">
              <div class="topic_type_timu_now_box box_sizing">
                <block v-for="(item, index) in detail.extend.FIB" :key="index">
                  <span v-if='item.index == 0' v-html="item.word"></span>
                  <input type="text" class="kong" v-if="item.index == 1" value="" :data-daan='item.word'
                    @change="inputChange" />
                </block>
              </div>
            </div>
          </div>

          <div v-if="tiXingType == 19">
            <div class="dati_time box_sizing flex_box flex_align_center justify_end">答题时间：{{datiTime}}
            </div>
            <div class="mp3_tool mp3_tool2 box_sizing">
              <div style="margin: 20px 0px">
                <mp3-tool ref="mp3" :src-url="detail.file"></mp3-tool>
              </div>
            </div>
            <div class="topic_type_answer_box box_sizing">
              <span class="cuoci_ans hand_change" v-for="(item, index) in detail.extend.HIW"
                :class="[item.bg == 1 ? 'bg_ans' : '']" :key="index" @click="selectSpan(index)"
                v-html="item.word"></span>
            </div>
          </div>
          <div class="over flex_box">
            <div class="hand_hover nextP" @click="nextTiMu(0)">{{ subtext }}</div>
          </div>
          <!-- 答题结束弹框 -->
          <!-- <div class="timeOver box_sizing">
                        <img src="../../assets/images/over.png" alt="">
                        <div>答题时间</div>
                        <div>即将结束了哦</div>
                    </div> -->
        </div>
      </div>
    </div>
    <footers></footers>
  </div>
</template>

<script>
import headers from "@/components/headers";
import footers from "@/components/footders";
import left from "@/components/left";
import right from "@/components/right";
import scrollpage from "@/components/scrollpage";
import AudioCmponent from "@/components/audiocmponent/audiocmponent.vue";
import "@/css/pagePing.css";
// import Recorder from "js-audio-recorder";
import SiriWave from "siriwave";
import Mp3Tool from "@/components/mp3Toolexam.vue";


// var recorder = new Recorder();
var intervalTime;
var daojishi;
var daojishiSort;
var daojishiBo;
var _this;
export default {
  components: {
    headers,
    footers,
    AudioCmponent,
    left,
    right,
    scrollpage,
    Mp3Tool
  },
  data() {
    return {
      classic: undefined,
      current: 0,
      mokaoId: [],
      tiMuId: "",
      id: "",
      name: "",
      user_exam_id: "",
      suoYin: 0,
      tiXingType: "",
      bofangTxt: "播放录音",
      mouseStatus: false,
      recordings: false, // 标记是否在录音
      intervaltimerid: "", // 间隔时间定时器编号
      detail: {},
      form: {
        time: "按住录音",
        audioUrl: "",
      },
      num: 60, // 按住说话时间
      // recorder: null,
      interval: "",
      audioFileList: [], // 上传语音列表
      startTime: "", // 语音开始时间
      endTime: "", // 语音结束
      auUrl: "", // 上传录音文件地址
      timeLen: 0,
      wordCount: 0,
      obj: {},
      huida: false,
      subtext: 'NEXT',
      status: false,
      title: "",
      daojishiTime: 0, //等待时间倒计时
      datiTime: "00:00:00",
      answerTime: 0,
      playUrl: require("../../assets/images/play.png"),
      zantingUrl: require("../../assets/images/zanting_icon@2x.png"),
      playStatus: false,
      spanShow: false,
      audio: null,
      tiemr: null,
      tiemrs: "00:00:00",
      timeLength: "00:00:00",
      tijiaoId: '',
      ansId: '',
      list: [],
      bofangStatus: false,
      daanObj: {},
      daanIndexObj: {},
      daanIndexArr: [],
      drop_index: null,
      drop_before_index: null,
      content: '',
      currentStatus: 0,
      choose: [],
      rightChoose: [],
      chooseOne: -1,
      chooseTwo: -1,
      componentKey: 1,
      topic_count: 0,
      topic_all_num: 0,
      exam_surplus: 0,
      exam_surplus_date: '',
      exam_surplus_sort: 0,
      exam_surplus_sort_date: '',
      exam_topic_type_index: -1,
      exam_time_arr: []
    };
  },
  computed: {
    filteredShowText() {
      return this.detail.show_text.filter(item => item !== '<br/>');
    }
  },
  watch: {
    answerTime: {
      handler(newVal, oldVal) {
        console.log(`答题时间现在=============>`, newVal);
        console.log(`答题时间上次=============>`, oldVal);
        if (newVal == 0) {
          console.log(`答题时间结束了，该自动提交跳转到下一题了`, 11111111111111111111);
        }
      },
      deep:true
    }
  },
  created() {
    _this = this;
    _this.audio = new Audio();
    _this.getParams();
    //_this.getTime();
  },
  mounted() {
  },
  // computed: {
  //   // 进度条
  //   scrollerwidth: function () {
  //     console.log(_this.timenum);
  //     // 进度条长度计算，当前时间除以总时间乘以百分比
  //     let a = _this.timenum; //当前剩余时间
  //     let b = _this.timeall; //音频总长
  //     var c = ((b - a) / b) * 100 + "%";

  //     // 保存进度
  //     _this.LastTime = a;
  //     return c;
  //   },
  // },
  destroyed() {
    clearInterval(intervalTime);
    clearInterval(daojishi);
    clearInterval(daojishiSort);
    clearInterval(daojishiBo);
  },
  methods: {
    computedWordCount() {
      let value = _this.content;

      // 替换中文字符为空格
      value = value.replace(/[\u4e00-\u9fa5]+/g, " ");
      // 将换行符，前后空格不计算为单词数
      value = value.replace(/\r|^\s+|\s+$/gi, "");
      // 多个空格替换成一个空格
      value = value.replace(/\s+/gi, " ");
      // 更新计数
      var length = 0;
      var match = value.match(/\s/g);
      if (match) {
        length = match.length + 1;
      } else if (value) {
        length = 1;
      }
      _this.wordCount = length
    },
    init_classic(classic_id) {
      _this.classic = new SiriWave({
        container: document.getElementById(classic_id),
        height: 100,
        autostart: false,
        // 显示比例
        ratio: 2,
        // 动画速度
        speed: 0.1,
        // 振幅
        amplitude: 1,
        // 频率(iOS风格)
        frequency: 6,
        // 曲线颜色 (iOS风格)
        color: "#f56207",
        // 覆盖容器
        cover: false,
        // 在画布上步骤数量
        pixelDepth: 0.02,
        // 插入速度
        lerpSpeed: 0.01,
      });
      console.log('_this.classic',_this.classic);
    },
    // 进入页面倒计时40秒开始录音
    daojishiBack() {
      clearInterval(daojishiBo);
      daojishiBo = setInterval(() => {
        if (_this.daojishiTime <= 0) {
          //console.log("倒计时录音状态", _this.recordings);
          // if (!_this.recordings && _this.audio_answer_time == null) {
          let kouyu_arr = [1,2,3,4,5];
          if(kouyu_arr.includes(_this.detail.topic_type_id)) {
          console.log('_this.recordings',_this.recordings);
            if(_this.recordings == false) {
              setTimeout(() => {
                //console.log('audioc');
                _this.$refs.AudioC.startRecorder();
                _this.getTime();
              }, 500);
            }
          }else{
            _this.getTime();
          }
          clearInterval(daojishiBo);
          // let playPromise;
          // playPromise = _this.$refs.audio.play();
          _this.playStatus = true;
        } else {
          _this.daojishiTime--;
        }
      }, 1000);
    },
    audioDuration(e){
      //console.log(e,'eeeeeeeeeeeeeeee');
      _this.timeLen = e.duration; // 实际录音时长
      _this.datiTime = _this.formateSeconds(e.show_time); // 展示录音时长
    },
    // 点击词汇
    selectSpan(i) {
      console.log("111");
      let listObj = _this.detail;
      if(listObj.extend.HIW[i].word != '&nbsp;') {
        listObj.extend.HIW.forEach((item, index) => {
          console.log(index);
          console.log(i);
          console.log(item);
          if (index == i && item.bg == 0) {
            item.bg = 1;
          } else if (index == i && item.bg == 1) {
            item.bg = 0;
          }
        });
      }
      _this.detail = listObj;
      _this.$forceUpdate();
    },

    // input change
    inputChange(e) {
      console.log(e);
      console.log(e.target.dataset.daan)
      console.log(e.target.value)
      var daanObj = _this.daanObj;
      daanObj[e.target.dataset.daan] = e.target.value
      _this.daanObj = daanObj;
      console.log(daanObj)
    },
    chooseSource(e) {
      //选择左边栏选项
      console.log(e);
      _this.chooseOne = e;
      _this.chooseTwo = -1;
    },
    chooseCorrect(e) {
      //选择右边栏选项
      console.log(e);
      _this.chooseTwo = e;
      _this.chooseOne = -1;
    },
    exchange() {
      console.log(_this.chooseOne);
      console.log(_this.chooseTwo);
      if (_this.chooseOne != -1) {
        var value = _this.choose[_this.chooseOne];
        _this.choose.splice(_this.chooseOne, 1);
        _this.rightChoose.push(value);
        _this.chooseOne = -1;
      } else if (_this.chooseTwo != -1) {
        var valueTwo = _this.rightChoose[_this.chooseTwo];
        _this.rightChoose.splice(_this.chooseTwo, 1);
        _this.choose.push(valueTwo);
        _this.chooseTwo = -1;
      } else {
        _this.$message.error("请选择要操作的选项！");
      }
    },

    chooseUp() {
      //上移
      var index = _this.chooseTwo;
      if (index != 0) {
        _this.rightChoose[index] = _this.rightChoose.splice(
          index - 1,
          1,
          _this.rightChoose[index]
        )[0];
      } else {
        _this.$message.error("已经是第一个数据了，不可以上移了");
      }
    },
    chooseDown() {
      //下移
      var index = _this.chooseTwo;
      if (index != _this.rightChoose.length - 1) {
        _this.rightChoose[index] = _this.rightChoose.splice(
          index + 1,
          1,
          _this.rightChoose[index]
        )[0];
      } else {
        _this.$message.error("已经是最后一个数据了，不可以下移了");
      }
    },

    // 点击事件
    dianjiClick(item) {
      console.log(item)
    },

    // 在目标元素中移动
    dragoverDiv(e) {
      // console.log(e);
      e.dataTransfer.dropEffect = 'copy';
      e.preventDefault()
    },

    // 正在拖动
    dragDiv(e) {
      // console.log('正在拖动', e)
    },
    // 拖动开始
    touchstart() {
      console.log("11111");
    },
    // 拖动中
    touchmove() {
      console.log("222222");
    },
    // 拖动结束
    touchend() {
      console.log("33333");
    },

    // 阻止默认事件
    dropOver(e) {
      e.preventDefault(); // 在拖动中阻止默认事件
    },

    // 设置拖拽元素的方式
    deagenterDiv(e) {
      console.log('444444444444444444')
      // console.log(e)
      e.dataTransfer.dropEffect = 'copy';
      e.preventDefault();
    },

    // 开始拖拽
    dragstartHandler(e,index) {
      console.log('1212121212222222222222222222拖拽')
      console.log(e.target.dataset.text);
      e.dataTransfer.effectAllowed = "copy";
      e.dataTransfer.setData("copyKey", e.target.dataset.text);
      _this.drop_index = index;
      console.log('this.drop_index',index);
      // 获取拖拽的元素
    },

    // 拖拽到此
    dropDiv(e) {
      if (_this.spanShow) {
        _this.$message.error("答题已完成不可再操作");
        return false;
      }
      // console.log('1111111111111111111111111111111111111释放')
      // console.log(e);

      // 将拖动元素旋转到目标区域中
      var copyKey = e.dataTransfer.getData("copyKey");
      console.log(copyKey);
      _this.daanObj[e.target.dataset.txt] = copyKey;
      console.log('xxxxxx',_this.daanObj,_this.daanIndexObj);
      if(_this.daanIndexObj[e.target.dataset.txt] >= 0) { // 判断是否已选择
        _this.daanIndexArr.splice(_this.daanIndexArr.indexOf(_this.daanIndexObj[e.target.dataset.txt]),1); // 删除对应的索引值
      }
      _this.daanIndexObj[e.target.dataset.txt] = _this.drop_index;
      _this.daanIndexArr.push(_this.drop_index);
      console.log('indexof',_this.daanIndexArr);
      // console.log('1111111111111111111111111111111111111释放')
      // console.log(e);
      // // 将拖动元素旋转到目标区域中
      // var copyKey = e.dataTransfer.getData('copyKey')
      // console.log(copyKey);
      // _this.daanObj[e.target.dataset.txt] = copyKey;
      // _this.$for
    },

    // 选择答案
    selectDaan(i) {
      let obj = _this.detail,
        strList = [];
      obj.extend.extend_MA.forEach((item, index) => {
        if (index == i && item.select == 0) {
          item.select = 1;
        } else if (index == i && item.select == 1) {
          item.select = 0;
        }
        if (item.select == 1) {
          strList.push(item.key);
        }
      });
      _this.detail = obj;
      _this.ansId = strList.join(",");
      console.log(_this.ansId);
    },

    // 提交这一题，请求下一题
    nextTiMu(is_end) {
      _this.submitTopic(is_end);
      if(_this.subtext == 'NEXT') {
        _this.tiMuId = _this.mokaoId[_this.suoYin];
        _this.tijiaoId = _this.mokaoId[_this.suoYin];
        if(!_this.tiMuId) {
          return;
        }

        _this.timeLen=0;
        _this.datiTime='00:00:00';
        clearInterval(intervalTime)
        _this.suoYin += 1;
        if(_this.tiXingType == 1 || _this.tiXingType == 2 || _this.tiXingType == 3 || _this.tiXingType == 4 || _this.tiXingType == 5) {
          _this.$refs.AudioC.resetIsVoice();
          _this.classic.dispose();
        }
        _this.getTiMu();
      }
    },

    // 播放题目（音频）
    playAudioTopic() {
      if (_this.playStatus) {
        _this.audio.pause();
        //_this.timeLength = _this.tiemrs;
        _this.playStatus = false;
        clearInterval(_this.tiemr);
        _this.audio.load();
      } else {
        _this.audio = new Audio();
        _this.audio.src = _this.detail.file;
        let playPromise,
          second = _this.detail.duration;
        playPromise = _this.$refs.audio.play();
        _this.playStatus = true;
        if (playPromise) {
          playPromise
            .then(() => {
              // 音频加载成功
              // 音频的播放需要耗时
              _this.tiemr = setInterval(() => {
                second--;
                //_this.timeLength = _this.formateSeconds(second);
                if (second <= 0) {
                  _this.audio.pause();
                  //_this.timeLength = _this.tiemrs;
                  _this.playStatus = false;
                  clearInterval(_this.tiemr);
                }
              }, 1000);
            })
            .catch((e) => {
              // 音频加载失败
              console.error(e);
            });
        }
      }
    },
    // 录音开始
    BeginEvenF() {
      console.log(`点击开始录音======================>`);
      // this.texsShow = true;
      // _this.text = "Recording...";
      // _this.classic.start()
      // _this.daojishiTime = 0;
      _this.daojishiTime = 0;
      if(_this.detail.topic_type_id != 2) {
        _this.$refs.audio.play(); //提示音效
      }
      _this.classic.start();
      _this.recordings = true;
      console.log("当前录音状态11", _this.recordings);
      // _this.mouseStart();
    },
    // 选择
    selectChange(obj, e) {
      var daanObj = _this.daanObj;
      //console.log('daanObj111',daanObj)
      let oobj = [];
      //console.log("当前的obj", obj);
      for (let m = 0; m < _this.detail.extend.FIB_RW.length; m++) {
        if (
          typeof _this.detail.extend.FIB_RW[m].word === "object" &&
          _this.detail.extend.FIB_RW[m].word == obj.word
        ) {
          for (let n = 0; n < _this.detail.extend.FIB_RW[m].word.length; n++) {
            _this.detail.extend.FIB_RW[m].word[n].StyleColor = false;
          }
        }
      }
      obj.word.forEach((item) => {
        if (item.daan == 1) {
          daanObj[item.word] = e.target.value;
          // 判断选中答案是否与正确答案相等
          if (item.word.indexOf(e.target.value) !== -1) {
            //console.log("选中答案");
            // 相等后循环题目数组
            for (let i = 0; i < _this.detail.extend.FIB_RW.length; i++) {
              // 将题目数组中有答案选项的数组取出
              if (typeof _this.detail.extend.FIB_RW[i].word === "object") {
                // 循环答案选项数组
                for (
                  let j = 0;
                  j < _this.detail.extend.FIB_RW[i].word.length;
                  j++
                ) {
                  // 将选中的答案数组添加属性
                  // 重置属性值（选中正确答案后再选择错误答案，点击完成后即使是错误的也会显示绿色，所以再次处将所有属性重置未false）
                  // _this.detail.extend.FIB_RW[i].word[j].StyleColor = false;
                  // console.log('当前的word', item.word+"=========="+_this.detail.extend.FIB_RW[i].word[j].word)
                  if (
                    _this.detail.extend.FIB_RW[i].word[j].word.indexOf(
                      e.target.value
                    ) !== -1
                  ) {
                    _this.detail.extend.FIB_RW[i].word[j].StyleColor = true;
                  }
                  console.log(
                    " _this.detail.extend.FIB_RW[i].word[j]",
                    _this.detail.extend.FIB_RW[i].word[j]
                  );
                }
              }
            }
          }
        }
      });
      //console.log("_this.detail", _this.detail.extend);
      _this.daanObj = daanObj;
      //console.log('daanObj',daanObj)
      //console.log('_this.detail.extend.FIB_RW',_this.detail.extend.FIB_RW)
    },
    // 长按说话
    mouseStart() {
      _this.daojishiTime = 0; //将等待时置为0
      _this.classic.start();
      //console.log("当前录音状态", _this.recordings);
      // recorder.start(); // 开始录音
    },
    playEvent() {
      _this.classic.start();
      // _this.playAudio()
    },
    notplayEvent() {
      _this.recordings = false;
      _this.classic.stop();
    },
    // 录音结束
    GetAudioDataEven(res) {
      //console.log(`录音结束的res============>`, res);
      _this.recorderData = res;
      _this.mouseEnd();
    },
    // 松开时上传语音
    mouseEnd() {
      _this.classic.stop();
      _this.recordings = false;
      console.log("3333333_this.recordings",_this.recordings);
      // recorder.stop(); // 结束录音
      _this.bofangStatus = true;
      // 手动获取录音时长
      //console.log(`获取停止时录音时长==============>`, recorder.duration);
      // 页面上每道题的答题时间停止计时

      // recorder.play()
      _this.submitUp();
    },

    // 播放录音
    playAudio() {
      _this.classic.start()
      // recorder.play();
    },

    // 上传音频
    submitUp() {
      let PCMBlob = _this.recorderData.WAV; //获取 PCM 数据
      //console.log(PCMBlob);
      _this.mouseStatus = true;
      var formData = new FormData();
      formData.append("file", PCMBlob);
      var url = "common/uploadRecord";
      _this.$uploadFile
        .post(url, formData)
        .then(function (res) {
          //console.log(res);
          _this.auUrl = res.data.url;
          //console.log(_this.auUrl);
          // _this.submitTopic()
          // _this.nextTiMu()

          // _this.$notify.success({
          //   title: "提示",
          //   message: "上传成功",
          //   duration: 1500,
          // });
        })
        .catch((err) => {
          // _this.$notify.error({
          //   title: "提示",
          //   message: "上传失败请重试",
          //   duration: 1500,
          // });
        });
    },

    // 获取下一题的同时提交这一道题
    submitTopic(is_end) {
      let params = {}
      if (_this.tiXingType == 1 || _this.tiXingType == 2 || _this.tiXingType == 3 || _this.tiXingType == 4 || _this.tiXingType == 5) {
        params = {
          user_exam_id: _this.user_exam_id,
          topic_id: _this.tijiaoId,
          content: _this.auUrl,
          exam_time: _this.timeLen,
        };
      } else if (_this.tiXingType == 9 || _this.tiXingType == 8 || _this.tiXingType == 14 || _this.tiXingType == 16 || _this.tiXingType == 17 || _this.tiXingType == 18) {
        params = {
          user_exam_id: _this.user_exam_id,
          topic_id: _this.tijiaoId,
          content: _this.ansId,
          exam_time: _this.timeLen,
        };
      } else if (_this.tiXingType == 19) {
        let objList = _this.detail.extend.HIW,
          list = [];
          objList.forEach((item) => {
            if (item.bg == 1) {
              list.push(item.word);
            }
        });
        params = {
          user_exam_id: _this.user_exam_id,
          topic_id: _this.tijiaoId,
          content: list.join(","),
          exam_time: _this.timeLen,
        };
      } else if (_this.tiXingType == 11 || _this.tiXingType == 12 || _this.tiXingType == 15) {
        params = {
          user_exam_id: _this.user_exam_id,
          topic_id: _this.tijiaoId,
          content: _this.daanObj,
          exam_time: _this.timeLen,
        };
      } else if (_this.tiXingType == 20 || _this.tiXingType == 13 || _this.tiXingType == 6 || _this.tiXingType == 7) {
        params = {
          user_exam_id: _this.user_exam_id,
          topic_id: _this.tijiaoId,
          content: _this.content,
          exam_time: _this.timeLen,
        };
      } else if (_this.tiXingType == 10) {
        // 阅读排序
        let str = [];
        _this.rightChoose.forEach((item) => {
          str.push(item.index);
        });
        params = {
          user_exam_id: _this.user_exam_id,
          topic_id: _this.tijiaoId,
          content: str.join(","),
          exam_time: _this.timeLen,
        };
      }

      if (localStorage.getItem("token")) {
        params.is_end = is_end;
        console.log('params.content',params.content);
        _this.$axios
          .post("exam.user_exam/submit", params)
          .then((res) => {
            console.log(res);
            if (res.code == 1) {
              _this.bofangStatus = false;
            } else {
              // _this.$message.error(res.msg);
            }
          })
          .catch((err) => {
            console.error(err);
            // _this.$message.error(err.msg);
          });
        if (_this.subtext == 'Submit') {
          _this.$message.success("考试完成");
            setTimeout(() => {
              _this.$router.replace({
                path: './mokaoScore',
                query: {
                  user_exam_id: _this.user_exam_id
                }
              });
            }, 1500);
        }
        // else {
        //   _this.$message.error("您还没答题，请先进行答题");
        // }
      } else {
        _this.$message.error("您还没登录，请先登录");
      }
    },

    // 获取题目列表
    getList() {
      var params = {
        exam_id: _this.id,
      };
      _this.$axios
        .post("exam.user_exam/create", params)
        .then((res) => {
          console.log(res);
          if (res.code == 1) {
            _this.user_exam_id = res.data.user_exam_id;
            _this.getMoKaoList();
            // _this.mokaoId = res.data;
          } else {
            _this.$message.error(res.msg);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },

    // 获取模考题目
    getMoKaoList() {
      var params = {
        user_exam_id: _this.user_exam_id,
      };
      _this.$axios
        .post("exam.user_exam/getInfo", params)
        .then((res) => {
          console.log(res);
          if (res.code == 1) {
            _this.mokaoId = res.data.topic_ids;
            _this.tiMuId = res.data.topic_ids[0];
            _this.tijiaoId = res.data.topic_ids[0];
            _this.exam_time_arr = res.data.exam_time;
            _this.exam_surplus = res.data.r_exam_time;
            _this.topic_count = res.data.count;
            _this.topic_all_num = res.data.all_num;
            _this.examSurplusInterval();
            _this.suoYin = 1;
            _this.getTiMu();
          } else {
            _this.$message.error(res.msg);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },

    // 考试时间倒计时
    examSurplusInterval() {
      daojishi = setInterval(() => {
        if(_this.exam_surplus <= 0) {
          // 考试时间结束，自动提交
          _this.subtext == 'Submit'
          _this.nextTiMu(1);
        } else {
          _this.exam_surplus_date = _this.formateSeconds(_this.exam_surplus);
          //console.log(_this.exam_surplus_date);
          _this.exam_surplus--;
        }
      },1000)
    },

    // 模块考试时间倒计时
    examSurplusSortInterval(topic_type_id)
    {
      console.log('topic_type_id',topic_type_id);
      let is_change = 0;
      let kouyu_arr = [1,2,3,4,5];
      let writing_arr = [6,7];
      let reading_arr = [8,9,10,11,12];
      let listening_arr = [13,14,15,16,17,18,19,20];
      if(kouyu_arr.includes(topic_type_id) && _this.exam_topic_type_index != 0) {
        _this.exam_topic_type_index = 0;
        is_change = 1;
      } else if(writing_arr.includes(topic_type_id) && _this.exam_topic_type_index != 1) {
        _this.exam_topic_type_index = 1;
        is_change = 1;
      } else if(reading_arr.includes(topic_type_id) && _this.exam_topic_type_index != 2) {
        _this.exam_topic_type_index = 2;
        is_change = 1;
      } else if(listening_arr.includes(topic_type_id) && _this.exam_topic_type_index != 3) {
        _this.exam_topic_type_index = 3;
        is_change = 1;
      }
      console.log('is_change',is_change);
      if (is_change == 1) {
        clearInterval(daojishiSort)
        _this.exam_surplus_sort = _this.exam_time_arr[_this.exam_topic_type_index].time;
        console.log('exam_surplus_sort',_this.exam_surplus_sort);
        daojishiSort = setInterval(() => {
          if(_this.exam_surplus_sort <= 0) {
            // 模块考试时间结束，自动跳转到下一模块
            _this.exam_topic_type_index++;
            _this.exam_surplus_sort = _this.exam_time_arr[_this.exam_topic_type_index].time;
            _this.tiMuId=_this.exam_time_arr[_this.exam_topic_type_index].first_topic_id;
            _this.suoYin=this.mokaoId.indexOf(_this.tiMuId)+1;
            _this.submitTopic(2);
            _this.getTiMu();
          } else {
            _this.exam_surplus_sort_date = _this.formateSeconds(_this.exam_surplus_sort);
            //console.log(_this.exam_surplus_sort_date);
            _this.exam_surplus_sort--;
          }
        },1000)
      }
    },

    // 获取时间
    getTime() {
      clearInterval(intervalTime);
      _this.timeLen=0;
      intervalTime = setInterval(() => {
        let kouyu_arr = [1,2,3,4,5];
        if(kouyu_arr.includes(_this.detail.topic_type_id)) {
          if(_this.recordings == false && _this.timeLen) {
            _this.timeLen = _this.timeLen + 1;
            _this.datiTime = _this.formateSeconds(_this.timeLen);
          }
        } else {
          _this.timeLen = _this.timeLen + 1;
          _this.datiTime = _this.formateSeconds(_this.timeLen);
        }
        if(_this.timeLen>=_this.answerTime){
          clearInterval(intervalTime);
          _this.nextTiMu();
        }
      }, 1000);
    },

    //将秒转化为时分秒
    formateSeconds(endTime) {
      let secondTime = parseInt(endTime); //将传入的秒的值转化为Number
      let min = 0; // 初始化分
      let h = 0; // 初始化小时
      let result = "";
      if (secondTime > 60) {
        //如果秒数大于60，将秒数转换成整数
        min = parseInt(secondTime / 60); //获取分钟，除以60取整数，得到整数分钟
        secondTime = parseInt(secondTime % 60); //获取秒数，秒数取佘，得到整数秒数
        if (min > 60) {
          //如果分钟大于60，将分钟转换成小时
          h = parseInt(min / 60); //获取小时，获取分钟除以60，得到整数小时
          min = parseInt(min % 60); //获取小时后取佘的分，获取分钟除以60取佘的分
        }
      }
      result = `${h.toString().padStart(2, "0")}:${min
        .toString()
        .padStart(2, "0")}:${secondTime.toString().padStart(2, "0")}`;
      return result;
    },

    // 选择答案
    selectDxDaan(i) {
      let obj = _this.detail;
      obj.extend.extend_SA.forEach((item, index) => {
        if (index == i) {
          item.select = 1;
          _this.ansId = item.key;
        } else {
          item.select = 0;
        }
      });
      _this.detail = obj;
    },

    // 获取模考题目
    getTiMu() {
      _this.timeLen=0;
      _this.datiTime='00:00:00';
      _this.daanObj = {};
      _this.daanIndexObj = {};
      _this.daanIndexArr = [];
      var params = {
        topic_id: _this.tiMuId,
      };
      _this.$axios
        .post("exercise.topic/getExamTopicInfo", params)
        .then((res) => {
          //console.log('getExamTopicInfo',res);
          if (res.code == 1) {
            _this.tiXingType = res.data.topic_type_id;
            _this.daojishiTime = res.data.wait_time
            _this.answerTime = res.data.answer_time
            if (res.data.topic_type_id == 6 || res.data.topic_type_id == 7) {
              _this.content = '';
            } else if (res.data.topic_type_id == 9) {
              // 阅读多选
              res.data.extend.extend_MA.forEach((item) => {
                item.select = 0;
              });
            } else if (res.data.topic_type_id == 11) {
              // 阅读填空
              let obj = {};
              res.data.extend.FIB.forEach((item, index) => {
                console.log(typeof (item.word))
                if (typeof item.word != "string") {
                  item.word.forEach((items, indexs) => {
                    if (items.index == 1) {
                      obj[items.word] = "";
                    }
                  });
                }
              })
              _this.daanObj = obj;
            } else if (res.data.topic_type_id == 12) {
              // / 阅读写作填空
              let obj = {};
              res.data.extend.FIB_RW.forEach((item, index) => {
                console.log(typeof (item.word))
                if (typeof item.word != "string") {
                  item.word.forEach((items, indexs) => {
                    if (items.daan == 1) {
                      obj[items.word] = "";
                    }
                  });
                }
              })
              _this.daanObj = obj;
            } else if (res.data.topic_type_id == 8) {
              //  阅读单选
              res.data.extend.extend_SA.forEach((item) => {
                item.select = 0;
              });
            } else if (res.data.topic_type_id == 10) {
              // 阅读排序
              _this.choose = res.data.extend.extend_sort;
            } else if (res.data.topic_type_id == 15) {
              // 听力填空
              let obj = {};
              res.data.extend.FIB.forEach((item, index) => {
                console.log(typeof (item.word))
                if (item.index == 1) {
                  obj[item.word] = '';
                }
              })
              _this.daanObj = obj;
            } else if (res.data.topic_type_id == 19) {
              res.data.extend.HIW.forEach(item => {
                item.bg = 0;
              })
            } else if (res.data.topic_type_id == 13 || res.data.topic_type_id == 20) {
              _this.content = '';
            } else if (res.data.topic_type_id == 14) {
              res.data.extend.extend_MA.forEach((item) => {
                item.select = 0;
              });
            } else if (res.data.topic_type_id == 16 || res.data.topic_type_id == 17 || res.data.topic_type_id == 18) {
              res.data.extend.extend_SA.forEach((item, index) => {
                item.select = 0;
              });
            }
            _this.detail = res.data;
            _this.auUrl = '';
            _this.wordCount = 0;
            _this.bofangStatus = false;
            _this.mouseStatus = false;
            _this.rightChoose = []
            //_this.timeLength = _this.formateSeconds(res.data.duration);
            _this.tiemrs = _this.formateSeconds(res.data.duration);
            //console.log('is_submit',_this.tiMuId,_this.mokaoId,_this.mokaoId[_this.mokaoId.length-1]);
            if(_this.tiMuId == _this.mokaoId[_this.mokaoId.length-1]) {
              _this.subtext = 'Submit';
            }
            _this.getTime();
          } else {
            _this.$message.error(res.msg);
          }
          _this.examSurplusSortInterval(res.data.topic_type_id);
          let kouyu_arr = [1,2,3,4,5];
          if(kouyu_arr.includes(res.data.topic_type_id)) {
            _this.daojishiBack();
            setTimeout(function(){
              _this.$refs.AudioC.changeTime({answer_time:_this.answerTime,allow_time:_this.answerTime-0.1});
            },200)
            // _this.$refs.AudioC.answer_time = _this.answerTime;
            // _this.$refs.AudioC.allow_time = _this.answerTime-0.1;
          }
          _this.$nextTick(()=>{
            let classic_id = _this.tiXingType == 1 ? 'siri-classic' : (_this.tiXingType == 3 ? 'siri-classic3' : 'siri-classic2')
            let el = document.getElementById(classic_id);
            console.log('当前的对象', el)
            if (el !== null){
              _this.componentKey += 1;
              _this.$refs.AudioC.isVoice = 1;
              _this.init_classic(classic_id);
            }
            let arr = [2,4,5,13,14,15,16,17,18,19,20,21,22,23];
            if(arr.includes(_this.tiXingType)) {
              _this.$refs.mp3.play(); //提示音效
            }
          })
        })
        .catch((err) => {
          console.error(err);
        });
    },

    // 获取传递过来的参数
    getParams() {
      _this.id = _this.$route.query.id;
      _this.name = _this.$route.query.name;
      if(_this.$route.query.user_exam_id) {
        _this.user_exam_id = _this.$route.query.user_exam_id
        _this.getMoKaoList();
      } else {
        _this.getList();
      }
    },

    // 返回上一页
    goBack() {
      _this.$router.go(-2);
    },
  },
};
</script>


<style>
.banner_detail {
  width: 100%;
  padding: 34px 35px;
  border-radius: 20px;
  background: rgba(255, 255, 255, 1);
  text-align: left;
}

.listTitle {
  width: 920px;
  height: auto;
  padding: 11px 20px;
  background: #f9f9f9;
  opacity: 1;
}

.RA {
  width: 40px;
  height: 24px;
  border: 1px solid #f27f6d;
  opacity: 1;
  border-radius: 12px;
  text-align: center;
  line-height: 24px;
  color: #f27f6d;
}

.save {
  width: 97px;
  height: 24px;
  background: #f27f6d;
  opacity: 1;
  border-radius: 12px;
  text-align: center;
  line-height: 24px;
  color: #ffffff;
}

.teacher {
  width: 912px;
  height: 76px;
  border: 1px solid #bdc4ce;
  opacity: 1;
  border-radius: 10px;
  margin: 10px 0px 40px 0;
  padding: 19px 22px;
}

.teacher img {
  width: 40px;
  margin-right: 24px;
}

.play {
  width: 100%;
  height: auto;
  text-align: left;
  background: none;
}

.play .time {
  color: #8c9198;
  font-size: 12px;
  margin-left: 20px;
}

.play .progress {
  width: 300px;
  margin: 0;
}

.play .p3 {
  line-height: 22px;
  margin-bottom: 6px;
  font-size: 14px;
}

.yin {
  width: 20%;
  height: 26px;
  padding-top: 7%;
}

.yin img {
  width: 20px;
  height: 20px;
  margin-right: 6px;
}

.p2 {
  font-size: 14px;
  line-height: 22px;
  text-align: left;
}

.p1 {
  width: 912px;
  height: auto;
  line-height: 22px;
  background: #f9f9f9;
  opacity: 1;
  padding: 35px;
  text-align: left;
}

.nextP {
  width: 78px;
  height: 30px;
  background: #f6704b;
  opacity: 1;
  border-radius: 4px;
  color: #ffffff;
  text-align: center;
  line-height: 30px;
  margin-top: 50px;
}

.over {
  width: 100%;
  justify-content: flex-end;
}

/* 答题结束弹框 */
.timeOver {
  width: auto;
  height: auto;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 10px;
  color: #ffffff;
  text-align: center;
  padding: 30px 60px;
  position: fixed;
  top: 15%;
  left: 50%;
  z-index: 999;
}

.timeOver img {
  width: 50px;
  margin: 0 auto;
  height: 50px;

  margin-bottom: 24px;
}
.topic_type_answer_box {
  word-break: break-word;
}
.my_quest3 {
  width: 100%;
  height: auto;
}
.choose17 {
  height: auto;
}
.mp3_tool2 {
  margin-bottom: 58px;
  padding: 24px 22px;
}
.topic_textarea_box {
  height: 400px !important;
}
.topic_textarea_box_wfd {
  height: 150px !important;
}
.hand_change {
  display: inline-block;
  cursor: pointer;
}
</style>
